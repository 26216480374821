.moxey-agreements {
  padding: 10rem 0;
  background: #070922;
  color: #fff;

  .content {
    color: #232526;
    padding: 2rem;
    margin-top: 2rem;
  }
}

.privacy-content {
  .c1 {
    font-weight: bold;
  }
}

.terms-content {
  .c7 {
    font-weight: bold;
  }
  .c1 {
    display: block;
    margin-bottom: 1rem;
  }
  .c9 {
    display: block;
  }
}

.data-policy-content {
  p {
    margin-bottom: 1rem;
  }
  ol li, ul li {
    margin-bottom: .25rem;
  }
}
