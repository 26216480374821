.blogs {
  &-list {
    padding: 0 0 5rem 0;
    background: #070922;
    .card{
      a{
        text-decoration: none;
      }
      &-title{
        color: #070922;
      }
    }
  }
}



.card-text {
  font-size: 14px;
}
