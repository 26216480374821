@import "../../sass/mixins/responsive";
@import "../../sass/animations";

.moxey {
  &-products {
    padding: 5rem 0 10rem 0;
    background: #060822;
    color: #fff;
    height: 100%;
    @include sm() {
      padding: 5rem 0 0 0;
    }

    &-header {
      margin: 10rem 0;
    }

    &-heading {
      text-align: center;
      font-weight: bolder;
      margin: 5rem 0 5rem 0;
      font-size: 5rem;
      @include sm {
        margin: 5rem 0;
        font-size: 3rem;
        word-wrap: break-word;
      }
    }

    &-img {
      padding-top: 30%;
      > img {
        height: 28rem;
        width: auto;
        filter: drop-shadow(1rem 2rem 3rem #13b0ab61);
      }
    }
  }

  &-product {
    height: 100%;
    padding: 30% 0 0 0;
    &-logo {
      @include sm() {
        height: 35px;
      }
    }

    &-img {
      @include sm() {
        height: 20rem;
        margin: 0 -2rem 2rem 0;
      }
    }
    &-headline {
      font-size: 3rem;
      margin: 2rem 0;
      @include sm() {
        font-size: 1.85rem;
      }
    }
    &-description {
      font-size: 1rem;
    }
  }
}
