@import "../../sass//mixins/responsive";

.moxey-contact {
  background: #070922;
  &-header {
    padding: 10rem 0;
    color: #fff;
    text-align: center;
    background: linear-gradient(rgba(0, 0, 0, 0.757), rgba(0, 0, 0, 0.749)),
      url("./contact_bg.png");
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    height: 60vh;
    @include sm() {
      height: 100%;
      padding: 10rem 0 3rem 0;
    }
  }
  &-body {
    padding: 0 0 5rem 0;
    margin-top: -25vh;
    @include sm() {
      margin-top: 0;
    }
    .btn-submit {
      background: #22b470;
      border: none;
    }
  }

  &-form {
    background: #fff;
    padding: 3rem;
    border-radius: 20px;
    @include sm() {
      padding: 2rem 1.5rem;
      border-radius: 10px;
    }
  }
}

.form-control {
  background: #f5f5f5;
  border: 1px solid #22b47000;
  transition: all 0.2s ease;
  &:focus,
  &:hover,
  &:active {
    border: 1px solid #22b470;
    box-shadow: none;
  }
}
