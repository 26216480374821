@import "../../sass/mixins/responsive";

.moxey-blog {
  padding: 5rem 0;
  background: #070922;
  height: 100%;
  color: #fff;
  &-header {
    padding: 5rem 0;
    min-height: 60vh;
    height: 100%;

    &.blog-bg-1 {
      background: linear-gradient(rgba(0, 0, 0, 0.757), rgba(0, 0, 0, 0.749)),
        url("./imgs/Blogs_01.png");
      background-position: top;
      background-size: cover;
      background-repeat: no-repeat;
    }
    &.blog-bg-2 {
      background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
        url("./imgs/Blogs_02.png");
      background-position: top;
      background-size: cover;
      background-repeat: no-repeat;
    }
    &.blog-bg-3 {
      background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
        url("./imgs/Blogs_03.png");
      background-position: top;
      background-size: cover;
      background-repeat: no-repeat;
    }
    &.blog-bg-4 {
      background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
        url("./imgs/Blogs_04.png");
      background-position: top;
      background-size: cover;
      background-repeat: no-repeat;
    }
    &.blog-bg-5 {
      background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
        url("./imgs/Blogs_05.png");
      background-position: top;
      background-size: cover;
      background-repeat: no-repeat;
    }
    &.blog-bg-6 {
      background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
        url("./imgs/Blogs_06.png");
      background-position: top;
      background-size: cover;
      background-repeat: no-repeat;
    }
    @include sm() {
      min-height: auto;
      padding: 5rem 0;
      height: 100%;
    }
  }

  @include sm() {
    h4 {
      font-size: 1.2rem !important;
      padding: 0 !important;
    }

    h1 {
      font-size: 2rem !important;
      margin-bottom: 2rem;
      padding: 0 !important;
    }
  }

  &-headline {
    height: 100%;
    @include sm() {
      width: 90%;
    }
  }

  &-content {
    margin-top: -100px;
    padding: 5rem;
    background-color: #fff;
    border-radius: 0.5rem;
    color: #232526;

    .heading {
      color: #0d6162;
      font-weight: bold;
    }

    @include sm() {
      margin: 0;
      padding: 2rem 1rem;
    }
  }
}

.moxey-recommendations {
  margin: 1rem 0;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fit, 300px);
  justify-content: center;
  color: #232526;
}

.blog-icons {
  filter: invert(100%);
  width: 16px;
  height: 16px;
}
