@import "../../sass/mixins/responsive";

.moxey-faq {
  padding: 5rem 0;
  background: #070922;
  height: 100%;
  color: #fff;
  &-header {
    padding: 5rem 0;
    min-height: 50vh;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.356), rgba(0, 0, 0, 0.486)),
      url("https://images.unsplash.com/photo-1586769852836-bc069f19e1b6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    @include sm() {
      min-height: auto;
      padding: 5rem 0;
      height: 100%;
    }
  }

  @include sm() {
    h4 {
      font-size: 1.2rem !important;
      padding: 0 !important;
    }

    h1 {
      font-size: 2rem !important;
      margin-bottom: 2rem;
      padding: 0 !important;
    }
  }

  &-headline {
    height: 100%;
    @include sm() {
      width: 90%;
    }
  }

  &-content {
    margin-top: -100px;
    padding: 0;
    background-color: #fff;
    border-radius: 0.5rem;
    color: #232526;

    &-header {
      .filters {
        padding: 2rem 0;
        display: flex;
        justify-content: center;
        @include sm() {
          padding: 1rem;
          justify-content: start;
          overflow-x: scroll;
        }
        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }

        &::-webkit-scrollbar-track {
          background: #22b47000;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 5px;
          background: #289b65;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #07619a;
        }
      }
    }

    @include sm() {
      margin: 0;
      margin-top: -50px;
    }
  }
}
