@import "../../sass/mixins/responsive";

.moxey-payments {
  padding: 10rem 0;
  background: #060822;
  color: #fff;
  @include sm {
    padding: 5rem 0;
  }
  &-headline {
    font-weight: bold;
    font-size: 4rem;
    text-align: center;
    margin: 5rem 0;
    @include sm {
      margin: 2rem 0;
      font-size: 3rem;
      word-wrap: break-word;
      text-align: left;
    }
  }
  &-img {
    > img {
      height: 34rem;
      width: auto;
      padding: 5rem 0 0 0;
      filter: drop-shadow(1rem 2rem 3rem #13b0ab61);
    }
  }

  &-feature-img {
    height: 20rem;
    margin: 2rem 0;
  }
  &-line-2 {
    font-size: 2rem;
    color: #22b470;
    margin: 2rem 0;
  }
  &-features-list {
    list-style: none;
  }
  &-feature {
    list-style-image: url("./imgs/charm_tick.svg");
    padding: 0 0 0.6rem 1rem;
  }
  &-feature-wrapper {
    margin: 10rem 0;
    @include sm() {
      margin: 2rem 0;
    }
    &:last-child {
      margin-bottom: 5rem;
    }
    padding: 3rem 0 0 0;
  }
}
