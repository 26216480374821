html {
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: rgb(51, 51, 51);
  }

  ::-webkit-scrollbar-thumb {
    background: #22b470;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #07619a;
  }
}

.text-gradient {
  background-image: url("./assets/text-bg-1.png");
  background-position: right;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: animate-gradient-text 4s;
  -webkit-animation: animate-gradient-text 4s;

  &.animated {
    animation: animate-gradient-text 4s infinite;
    -webkit-animation: animate-gradient-text 3s infinite;
  }
}

@keyframes animate-gradient-text {
  0% {
    background-position: right;
  }

  50% {
    background-position: center left;
  }

  100% {
    background-position: right;
  }
}
