@import "../../../sass//mixins/responsive";

.moxey-footer {
  padding: 5rem 0 0 0;
  background: #060822 url("./footer_bg.svg");
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  height: 100%;
  &-logo {
    height: 30px;
    width: auto;
  }
  &-line {
    &-1 {
      margin: 2rem 0;
      color: rgb(159, 159, 159);
      font-size: 1.1rem;
    }
    &-2 {
      font-style: italic;
    }
  }
  &-menu {
    list-style: none;
    padding: 0;
    &-item {
      margin-bottom: 0.5rem;
      a {
        color: rgb(159, 159, 159);
      }
    }
  }

  &-end {
    padding: 2rem 0;
    background: rgba(0, 0, 0, 0.374);
    font-size: 1rem;
    .icon {
      height: 24px;
      width: auto;
      margin-right: 0.5rem;
    }
    @include sm() {
      .container {
        flex-direction: column;
        gap: 2rem;
        padding: 2rem;
      }
    }
  }

  .get-app {
    &-text {
      color: rgb(159, 159, 159);
      @include sm() {
        text-align: center;
      }
    }
    &-buttons {
      justify-content: space-around;
      @include sm() {
        display: flex;
      }
    }
    &-icon {
      &.disabled {
        opacity: 0.5;
      }
    }
    @include sm() {
      margin-top: 2rem;
    }
  }

  &-social-links {
    a {
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
    img {
      height: 20px;
      width: auto;
    }
  }
}

.pci-logo {
  width: 100px;
  height: auto;
  filter: brightness(100);
}
