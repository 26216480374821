@import "../../sass/mixins/responsive";

.moxey-blogs {
  background: #22b470 url("./imgs/blog_bg.svg");
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 35%;
  padding: 5rem 0;
  width: 100%;
  &-line-1 {
    color: #fff;
    font-size: 4rem;
    font-weight: bold;
    @include sm() {
      word-break: break-word;
      font-size: 3rem;
    }
  }

  &-cards-wrapper {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(3, 240px);
    overflow-x: scroll;
    padding: 1rem 0;
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #22b47000;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: #289b65;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #07619a;
    }
  }
}

.card-date {
  font-size: 10px;
}

.blog-card {
  .title {
    @supports (-webkit-line-clamp: 2) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .short-desc {
    @supports (-webkit-line-clamp: 2) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
}

.btn-blog {
  width: fit-content !important;
}
