@import "../../../sass/mixins/responsive";

.primary-action-btn {
  display: inline-block;
  border: none;
  font-weight: 500;
  background: #22B470; 
  text-shadow: 2px 2px 5px #24242467;
  @include sm() {
    width: max-content;
  }

  img{   
    filter: drop-shadow(2px 2px 5px #24242467);    
    transition: filter 0.5s ease;
  }

  &:hover{    
    text-shadow: none;
    img{     
      filter: invert(100%);
    }
  }

  &.dark {
    background: #060822; /* fallback for old browsers */    
    &:hover{
      color: #fff;
    }
  }

  .btn-text {
    // text-shadow: 0 2px 3px rgba(0, 0, 0, 0.308);
    font-size: 1rem;
  }
}

.secondary-action-btn {
  border: none;
  font-weight: 500;
  display: inline-block;
  text-shadow: 2px 2px 5px #24242467;
  @include sm() {
    width: max-content;
  }

  .btn-text {
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.308);
    font-size: 1rem;
  }
}
