@keyframes up-down {
  0% {
    transform: translateY(0px);
    filter: drop-shadow(1rem 1rem 2rem #0d61629c);
  }
  50% {
    transform: translateY(30px);
    filter: drop-shadow(1rem 2rem 4rem #0d616223);
  }
  100% {
    transform: translateY(0px);
    filter: drop-shadow(1rem 1rem 2rem #0d61629c);
  }
}
