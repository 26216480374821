@import "../../sass/animations";
@import "../../sass/mixins/responsive";

.moxey-hero {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background: #060822;
  padding: 4% 0;
  margin: 0;
  background: #060822 url("./imgs/hero_bg_rails.png");
  background-repeat: no-repeat;
  background-position: right;
  @include sm() {
    padding: 0 0 5rem 0;
    min-height: 100%;
  }  

  .hero-line {
    padding-top: 7rem;
    &-1 {
      color: #fff;
      font-size: 2rem;
    }
    &-2 {
      font-size: 3.5rem;
      font-weight: 500;
      background: -webkit-linear-gradient(#3fae7f, #1aa5e8);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &-3 {
      color: #fff;
      font-size: 2rem;
    }

    @include sm() {
      &-1 {
        font-size: 1.5rem;
      }
      &-2 {
        font-size: 2.5rem;
      }
      &-3 {
        font-size: 1.5rem;
      }
    }
  }

  &-banner-wrapper {
    display: flex;
    justify-content: flex-end;
    .banner-img {
      width: 37rem;
      height: auto;
      margin: -1rem 0 0 0;
      animation: up-down 5s ease-in-out infinite;
    }

    @include sm() {
      justify-content: center;
      overflow: hidden;
      .banner-img {
        min-height: 352px;
        width: 22rem;
        filter: drop-shadow(1rem 1rem 2rem #0d61629c);
        animation: none;
        margin-bottom: 2rem;
      }
    }
  }

  // sub-section
  &-sub {
    position: relative;
    height: 100%;
    background: #060822;
    padding: 5% 0 0 0;
    text-align: center;
    background: #060822 url("./imgs/sub_section_bg.svg");
    background-repeat: no-repeat;
    background-position: top;
    transition: all;
    animation: animate-background 1s ease-in;
    overflow: hidden;

    @include sm() {
      background: #060822 url("./imgs/sub_section_bg-sm.png");
      background-repeat: no-repeat;
      background-position: top;
      background-size: 390px 360px;
      padding: 3rem 1rem 5rem 1rem;
    }

    &-header {
      color: #fff;
      font-size: 4rem;
      font-weight: semibold;
      margin: 5rem 0 0 0;
      @include sm() {
        font-size: 2.5rem;
        padding: 0;
        margin: 1rem;
        text-align: center;
      }
    }

    &-content {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      text-align: center;
      padding: 3rem 0;
      position: relative;
      margin-right: 1rem;

      @include sm() {
        padding: 1rem 0;
      }

      .mobile {
        position: inherit;
        width: auto;
        height: 420px;
        filter: drop-shadow(0rem 1rem 1rem #06110d30);
        @include sm() {
          height: 215px;
        }

        &:nth-child(1) {
          margin-right: -1rem;
          z-index: 0;
        }
        &:nth-child(2) {
          margin-right: -1rem;
          z-index: 1;
        }
        &:nth-child(3) {
          height: 500px;
          margin-right: -1rem;
          z-index: 10;
          @include sm() {
            height: 240px;
          }
        }
        &:nth-child(4) {
          margin-right: -1rem;
          z-index: 1;
        }
        &:nth-child(5) {
          z-index: 0;
          margin-right: -1rem;
        }
      }
    }
  }
}

.moxey-watch {
  background: rgba(0, 0, 0, 0.555);
  &-body {
    iframe {
      width: 100%;
      height: 80vh;
      border-radius: 1rem;
      @include sm() {
        height: auto;
      }
    }
  }
}

.hero-cta {
  display: flex;
  align-items: center;
  @include sm() {
    flex-direction: column;
    display: none;
  }
}
