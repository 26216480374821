$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xlg: 1200px;

// Small devices (landscape phones, 576p)
@mixin sm() {
  @media (max-width: $breakpoint-sm) {
    @content;
  }
}

// Medium devices (tablets, 768px)
@mixin md() {
  @media (max-width: $breakpoint-md) {
    @content;
  }
}

// Large devices
@mixin lg() {
  @media (max-width: $breakpoint-lg) {
    @content;
  }
}

// Extra Large devices
@mixin xl() {
  @media (max-width: $breakpoint-lg) {
    @content;
  }
}
