@import "../../sass/mixins/responsive";

.moxey-support {
  padding: 10rem 0;
  background: #22b470;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  position: relative;
  overflow: hidden;
  @include sm() {
    padding: 5rem 0;
  }
  &-bg {
    content: "";
    background-image: url("./imgs/support_bg.svg");
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 7%;
  }
  &-headline {
    font-weight: bold;
    @include sm() {
      font-size: 3rem;
      width: 100% !important;
    }
  }
  &-line-1 {
    font-size: 1.2rem;
    margin: 2rem 0;
  }
  &-list {
    list-style: none;
    font-size: 1.2rem;
  }
  &-feature {
    list-style-image: url("./imgs/charm_tick.svg");
    padding: 0 0 0.6rem 1rem;
  }

  &-img {
    width: auto;
    height: 25rem;
    @include sm() {
      margin: 5rem 0 0 0;
      width: 100%;
      height: auto;
    }
  }

  &-action {
    gap: 1rem;
    @include sm() {
      width: 70%;
      margin: 0 auto;
      flex-direction: column;
      a {
        margin: 0 0 1rem 0;
      }
    }
  }
}

.btn-contact {
  background-color: #2a2e82;
}

.icon {
  width: 20px;
  height: auto;
}
