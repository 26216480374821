@import "../../../sass/variables";

.moxey-navbar {
  // add styles here
  background: #070922 !important; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #070922 40%,
    #07092200
  ) !important; /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #070922c0 40%,
    #07092200
  ) !important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  .action-btn {
    border-color: #00ac94;
    border-width: 2px !important;
  }

  .nav-item {
    margin-right: 2rem;
    font-size: 0.9rem;
  }

  .navbar.navbar-nav {
    height: 100%;
  }
}

.dropdown {
  &-menu {
    width: 300px;
  }
  &-list {
    color: #fff;
    list-style: none;
  }
  &-item {
    background: transparent !important;
    &-text {
      &:hover {
        color: rgb(255, 255, 255) !important;
      }
      color: rgb(189, 189, 189) !important;
    }
  }
}

.ad-banner {
  padding: 6rem 0 0 0;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background: #070922;
  backdrop-filter: blur(5px);
  transition: all 0.5s ease-in-out;
  background: linear-gradient(to bottom ,#070922 20%, #070922f1 20%,#07092250 60%);
  
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1022;
  overflow: hidden;
  &-body {
    display: grid;
    grid-auto-rows: 1fr 4fr;
    .close {
      min-height: 200px;
      flex-grow: 1;
    }
  }
  &-content {
    background: #23b472;
    padding: 2rem 0 0 0;
  }
  &-img {
    width: 100%;
    height: 340px;
    object-fit: cover;
    object-position: top;
  }
}

.nav-icon,
.nav-text {
  transition: 0.25s ease-in-out;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
}

.open {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
}

.sidebar {
  color: #fff;
  background: #070922 !important;
  max-width: 300px;

  .menu-wrapper {
    .menu-bar {
      list-style-type: none;
    }
  }
}
