@import "../../sass/mixins/responsive";

.moxey-about {
  height: 100%;
  padding: 10% 0;
  background: #060822 url("./imgs/about_bg.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  color: #fff;
  &-logo {
    height: 6rem;
    width: auto;
    margin: 2rem 0 1.5rem 0;
    @include sm() {
      height: auto;
    }
  }

  &-line {
    &-1 {
      font-size: 3rem;
    }
    &-2 {
      font-weight: lighter;
      font-size: 2.5rem;
      // margin-bottom: 8rem;
      @include sm() {
        font-size: 2rem;
        margin-bottom: 3rem;
      }
    }
  }
  &-info {
    border-radius: 1rem;
    background-color: rgba(255, 255, 255, 0.911);
    padding: 2rem;
    &-text {
      font-size: 1.2rem;
      color: #232526;
      font-weight: medium;
    }
    &-line-1 {
      color: #232526;
    }
  }
}
