@import "../../../sass/animations";

.moxey-logo {
  width: auto;
  height: 1.5rem;
}

#moxey-logo-x {
  transition: all 5s ease-in-out !important;
  animation: rotate 5s !important;
}

@keyframes rotate {
  from {
    transform: translate(0deg) !important;
  }
  to {
    transform: translate(360deg) !important;
  }
}
