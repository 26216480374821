@import "../../sass//mixins/responsive";

.blog {
  &-hero {
    padding: 1rem 0;
    background: #070922;
    height: 100%;
    color: #fff;
    @include sm() {
      padding: 3rem 0 2rem 0;
    }
  }
  &-section-heading {
    margin: 0 0 3rem 0;
  }
}
